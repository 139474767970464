import { apiUrlGet } from "common/api/api_common";
import { axiosNotes } from "common/api/axios/notes_axios";
import React from "react";

const NotesStart = () => {
  const [displayName, setDisplayName] = React.useState<string>("");
  React.useEffect(() => {
    const urlDashboard = apiUrlGet("dashboard");
    axiosNotes.get(urlDashboard, { withCredentials: true }).then((res: any) => {
      console.log("res", res);
      setDisplayName(res.data.name);
    });
  }, []);

  return <div>Hello World from Pipeline! Display name: {displayName}</div>;
};

export default NotesStart;
