import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { DivMain } from "./style/layout_common.style";

export default function MainView() {
  return (
    <DivMain>
      <Div>
        <Outlet />
      </Div>
    </DivMain>
  );
}

const Div = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  color: #764141;
  overflow: auto;
  margin-left: 2%;
  margin-top: 2rem;
  width: 96%;
`;
