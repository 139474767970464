import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
// import InputField from "components/UI/InputField";

const LoginForm = () => {
  return (
    <Form>
      <TextField
        name="un"
        // control={control}
        label="Korisničko ime"
        defaultValue={""}
      />
      <TextField
        name="pw"
        // control={control}
        label="Lozinka"
        defaultValue={""}
        type="password"
      />
      <Button type="submit">OK</Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  &&& div {
    margin-bottom: 1rem;
  }
`;

export default LoginForm;
