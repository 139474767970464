import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotesStart from "components/pages/NotesStart";
import MainView from "./MainView";
import LoginView from "./LoginView";
import LoginPage from "components/pages/LoginPage";
import EditorTest from "components/pages/EditorTest";
import Authrequired from "components/pages/Authrequired";

export default function NotesMainContent() {
  return (
    <Router>
      <Routes>
        <Route element={<MainView />}>
          <Route path="/" element={<NotesStart />} />
          <Route path="/editor-test" element={<EditorTest />} />
          <Route path="/authrequired" element={<Authrequired />} />
        </Route>
        <Route element={<LoginView />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
