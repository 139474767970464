import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";
import { NavLink } from "react-router-dom";
import { apiUrlGet } from "common/api/api_common";

const LoginPage = () => {
  const authGoogleUrl = apiUrlGet("auth/google");

  return (
    <DivLogin>
      <LoginForm />
      <Div>
        <NavLink to={authGoogleUrl}>Login via Google</NavLink>
      </Div>
    </DivLogin>
  );
};

const DivLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Div = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export default LoginPage;
