import { envTypeGet } from "common/common";

export type UrlBases = {
  auth: string;
  apkores: string;
};

export type ApiType = "auth" | "apkores";

export const apiUrlBaseGet = (): string => {
  const envType = envTypeGet();
  let urlBase = "http://localhost:3001";
  if (envType === "production") {
    urlBase = "https://notes-back.prosolver.rs";
  }

  return urlBase;
};

export const apiUrlGet = (path: string = ""): string => {
  const urlBase: string = apiUrlBaseGet();
  const url = urlBase + "/" + path;
  return url;
};
